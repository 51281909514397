import React, { useEffect, useState } from "react";
import CsrPoliciesBanner from "../components/CsrPoliciesBanneer/CsrPoliciesBanner";
import useFetch from "../hooks/useFetch";

const TermsOfUse = () => {
  const url = "/api/privacy-policy";
  const { resData } = useFetch(url);
  const [termsData, setTermsData] = useState(null);
  useEffect(() => {
    if (resData && resData.data) {
      const termsOfUse = resData.data.policyList.find(
        (obj) => obj.title.toLowerCase() === "terms of use"
      );
      setTermsData(termsOfUse);
    }
  }, [resData]);
  return (
    <>
      <CsrPoliciesBanner />
      <section class="policy-sec">
        <div class="container custom-container">
          <div class="policies-content">
            <h3 className="text-uppercase">{termsData && termsData.title}</h3>
            <p
              dangerouslySetInnerHTML={{
                __html: termsData && termsData.description,
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsOfUse;
