import React from 'react'
import { Link } from 'react-router-dom'

const CsrPoliciesBanner = () => {
  return (
   <div className="cmn-banner-page news-banner policy-banner">
  <div className="container custom-container">
    <div className="row">
      <div className="col-12">
        <div className="cmn-banner-content">
          <h2 className="cmn-banner-title">POLICIES</h2>
          <Link to="/policies" className="cmn-text text-white pt-3 d-inline-block">Policies</Link>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default CsrPoliciesBanner
