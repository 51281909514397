import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./Header";
import Footer from "./Footer";
import useFetch from "../../hooks/useFetch";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";

export default function RootLayout() {
  const url = "/api/setting";
  const { resData } = useFetch(url);

  return (
    <html lang="en">
      <head>
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="fontawesome-free-6.5.1-web/css/all.min.css"
        />
        <link rel="stylesheet" href="css/aos.css" />
        <link rel="stylesheet" href="css/bootstrap.min.css" />
        <link rel="stylesheet" href="css/slick.css" />
        <link rel="stylesheet" href="css/style.css" />
        <link rel="stylesheet" href="css/mediaquery.css" />
      </head>
      <body>
        <div id="site-page">
          <div className="site-content">
            <Header
              headerData={
                resData &&
                resData.data &&
                resData.data.settings[0] &&
                resData.data.settings[0]
              }
            />
            <Outlet
              context={
                resData &&
                resData.data &&
                resData.data.settings[0] &&
                resData.data.settings[0]
              }
            />
            <Footer
              footerData={
                resData &&
                resData.data &&
                resData.data.settings[0] &&
                resData.data.settings[0]
              }
            />
          </div>
        </div>
        <script src="/assests/js/bootstrap.min.js"></script>
        <script src="/assests/js/custom.js"></script>
        <script src="/assests/js/timeline.js"></script>
      </body>
    </html>
  );
}
