import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/bootstrap.min.css";
import "./assets/css/slick.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./index.css";
import "./assets/css/aos.css";
import "./assets/css/style.css";
import "./assets/css/mediaquery.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from "./Elements/ToastContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ToastProvider>
      <App />
    </ToastProvider>
  </BrowserRouter>
);
